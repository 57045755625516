import React, { useEffect, useRef } from 'react';
import {
  Typography, Button, Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import PhoneHelp from '../need-help/we-are-here-to-help/PhoneHelp';
import { sendCustomFullStoryEvent } from '../../modules/vendor/fullstory/FullStoryUpdater';
import styles from './styles';
import { isVideoChatAvailable } from '../../modules/admissions/utilities';

export default function CtaImageCard({
  id,
  header,
  heading,
  contentInfo,
  CTAlabel,
  ctaURL,
  ctaDisabled,
  handleOnClick,
  phoneNumber,
  children,
}) {
  const classes = styles();
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClick = () => {
      const { pathname } = window.location;
      const pageName = pathname.replace('/application/admissions/', '');
      sendCustomFullStoryEvent('ID_VERIFY_VIDEO_CHAT_CLICK', {
        where: pageName,
        when: moment().format('MMMM Do YYYY, h:mm:ss a'),
      });
    };
    const button = buttonRef.current;
    if (button) {
      button.addEventListener('click', handleClick);
    }
    return () => {
      if (button) {
        button.removeEventListener('click', handleClick);
      }
    };
  }, []);

  return (
    <div id={id} className={clsx(classes.root, '')}>
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.imageContainer}>
          <div className={classes.image} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.videoChatContainer}>
            <Typography
              variant={heading}
              className={classes.header}
              style={{ fontSize: '2rem!important' }}
            >
              {header}
            </Typography>
            {contentInfo ? (
              <Typography className={classes.paragraph}>
                {contentInfo}
              </Typography>
            ) : null}
            <div className={classes.contactInfo}>
              {(ctaURL && handleOnClick) && (
                <span ref={buttonRef}>
                  <Button
                    className={clsx(classes.cta, 'mainBtn colorOverBtn')}
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    href={ctaURL}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleOnClick('video')}
                    disabled={ctaDisabled}
                  >
                    {CTAlabel}
                  </Button>
                </span>
              )}
              {(ctaURL && !handleOnClick) && (
                <span ref={buttonRef}>
                  <Button
                    className={clsx(classes.cta, 'mainBtn colorOverBtn')}
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    href={ctaURL}
                    target="_blank"
                    rel="noreferrer"
                    disabled={ctaDisabled}
                  >
                    {CTAlabel}
                  </Button>
                </span>
              )}
              {(!ctaURL && handleOnClick) && (
                <span ref={buttonRef}>
                  <Button
                    className={clsx(classes.cta, 'mainBtn colorOverBtn')}
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    onClick={() => handleOnClick('video')}
                    disabled={!isVideoChatAvailable() || ctaDisabled}
                  >
                    {CTAlabel}
                  </Button>
                </span>
              )}
              {phoneNumber ? (
                <PhoneHelp
                  phoneNumber={phoneNumber}
                  ariaLabel={`${phoneNumber} is your identity verify phone number`}
                />
              ) : null}
            </div>
            {children}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

CtaImageCard.defaultProps = {
  id: '',
  header: 'Add a header',
  heading: 'h2',
  contentInfo: '',
  ctaURL: null,
  ctaDisabled: false,
  handleOnClick: null,
  CTAlabel: 'Add a button label',
  phoneNumber: '',
  children: null,
};

CtaImageCard.propTypes = {
  id: PropTypes.string,
  header: PropTypes.string,
  heading: PropTypes.string,
  contentInfo: PropTypes.string,
  ctaURL: PropTypes.string,
  ctaDisabled: PropTypes.bool,
  handleOnClick: PropTypes.func,
  CTAlabel: PropTypes.string,
  phoneNumber: PropTypes.string,
  children: PropTypes.element,
};
